img {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

a {
  text-decoration: none;
  color: #0085eb;
}

/* Hide number input spin box */
input[type="number"]::-webkit-inner-spin-button {
  display: none;
}
/* Fallback for other browsers */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}
